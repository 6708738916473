/* eslint-disable react/no-array-index-key */
/* global tw */
import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Img from 'gatsby-image'
import { DiscussionEmbed } from 'disqus-react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const { NODE_ENV } = process.env

const Container = styled('div')`
  ${tw('pb-4')};
`

const Header = styled('div')`
  ${tw('relative mb-6')};
`

const FluidImage = styled(Img)`
  ${tw('mt-4 lg:-mt-4 -mx-4 md:mx-0 rounded-br showdow-lg')};
  height: max(25vh, 25vw);
  border-bottom-left-radius: 5rem;
`

const Title = styled('h2')`
  ${tw('absolute p-5 my-0 bg-yellow-500 rounded shadow')};
  max-width: calc(100% - 40px);
  left: 30px;
  bottom: 30px;
`

const PublishDate = styled('h4')`
  ${tw('absolute p-2 my-0 bg-gray-800 text-white rounded shadow')};
  right: 10px;
  bottom: -20px;
`

const Tags = styled('div')`
  ${tw('hidden lg:block absolute')};
  left: 30px;
  bottom: 110px;
`

const Tag = styled('span')`
  ${tw('py-1 px-2 mx-1 bg-blue-800 text-white text-sm font-semibold rounded-full shadow')};
`

const Attribution = styled('h6')`
  ${tw('absolute px-1 my-0 bg-white text-gray-800 rounded shadow')};
  top: 10px;
  right: 10px;
`

const TimeToRead = styled('h6')`
  ${tw('absolute text-gray-700 italic')};
  right: 10px;
  bottom: -40px;
`

const Article = styled('article')`
  ${tw('max-w-3xl py-6 mx-auto')};
  a:not(.footnote-ref):not(.footnote-backref) {
    background: linear-gradient(to bottom, #ed8936 0%, #ed8936 100%);
    background-size: 2px 2px;
    transition: background-size 0.2s;
    ${tw('bg-left-bottom bg-repeat-x no-underline')};
    &:hover {
      background-size: 2px 16px;
    }
  }
  ul {
    ${tw('pl-4')};
    li {
      &.task-list-item {
        ${tw('list-none')};
      }
    }
  }
  table {
    ${tw('w-full border-collapse shadow whitespace-nowrap')};
    th,
    td {
      ${tw('py-2 px-4 border border-solid border-gray-400 ')};
    }
    th {
      ${tw('bg-gray-700 text-white')};
    }
  }
  sup {
    ${tw('text-xs')};
    vertical-align: super;
    line-height: 0;
  }
  .gria-image-wrapper {
    ${tw('shadow-xl')};
    .gria-image {
      ${tw('p-4 mb-4 bg-white')};
      object-fit: contain !important;
    }
  }
  .table-wrapper {
    ${tw('sm:w-full overflow-x-auto')};
  }
  .footnotes {
    ${tw('text-xs')};
    ol {
      li {
        ${tw('mb-2')};
        p {
          ${tw('my-0')};
        }
      }
    }
  }
`

const Comments = styled(DiscussionEmbed)`
  ${tw('max-w-3xl p-4 mx-auto rounded shadow')});
`

export const pageQuery = graphql`
  query ($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      timeToRead
      frontmatter {
        path
        date(formatString: "MMMM DD, YYYY")
        title
        tags
        attribution
        allowComments
      }
      featuredImage {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`

function PostPage({ data }) {
  const { markdownRemark } = data
  const { html, timeToRead, frontmatter, featuredImage } = markdownRemark

  return (
    <>
      <Helmet title={`Marc Santos | ${frontmatter.title}`} defer={false} />
      <Container>
        <Header>
          <FluidImage fluid={featuredImage.childImageSharp.fluid} />
          <Title>{frontmatter.title}</Title>
          <PublishDate>{frontmatter.date}</PublishDate>
          <Tags>
            {frontmatter.tags.map((tag, tagIndex) => (
              <Tag key={tagIndex}>{tag}</Tag>
            ))}
          </Tags>
          {frontmatter.attribution && <Attribution>Photo by {frontmatter.attribution}</Attribution>}
          <TimeToRead>
            <FontAwesomeIcon icon="clock" /> {timeToRead} min read
          </TimeToRead>
        </Header>
        <Article dangerouslySetInnerHTML={{ __html: html }} />
        {NODE_ENV === 'production' && frontmatter.allowComments === true && (
          <Comments shortname="marcsantos" config={{ identifier: frontmatter.path }} />
        )}
      </Container>
    </>
  )
}

PostPage.propTypes = {
  data: PropTypes.any.isRequired,
}

export default PostPage
/* eslint-enable react/no-array-index-key */
